import BasePage from '../../../../components/table_page';
import request from '../../../../utils/request';
import Form from '../../components/process_approve/approve.vue';
import CopyHandle from '../../components/copy_handle/copy_handle.vue';
import Print from './print';

export default {
  extends: BasePage,
  components: {
    Form,
    CopyHandle,
  },
  data() {
    return {};
  },
  created() {
    this.getConfigList('work-flow-dealt');
  },
  methods: {
    modalClick({ val, row }) {
      const { code, name } = val;
      if (code === 'workFlowBack') {
        this.$confirm('是否确认追回流程？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            request
              .get('/activiti/runtimeController/recallTasks', {
                crmProcessInstanceId: row.crmProcessInstanceId,
              })
              .then((res) => {
                if (res.code === 200) {
                  this.$message.success(res.message);
                  this.getList();
                }
              });
          })
          .catch(() => {});
      } else if (code === 'view') {
        this.modalConfig.title = '查看';
        this.modalConfig.type = 'EmptyDrawer';

        this.formConfig = {
          code,
          row,
        };
        this.openModal();
      } else if (code === 'circulated') {
        this.modalConfig.title = name;

        this.formConfig = {
          code,
          row,
        };
        this.formName = 'CopyHandle';

        this.openModal();
      } else if (code === 'print') {
        request.post('/mdm/mdmdictdata/list', { dictTypeCode: 'print_process_key' }).then((res) => {
          if (res.success) {
            for (const item of res.result) {
              let processKey1 = null;
              const processKeyList = item.process_key.split(',');
              processKey1 = processKeyList.find((v) => v === row.processKey);
              // if (item.process_key.indexOf(row.processKey) >= 0) {
              if (processKey1) {
                new Print({
                  id: row.businessId,
                  logId: row.processInstanceId,
                  type: item.dictCode,
                  rowId: row.id,
                  businessNo: row.businessNo,
                  processKey: row.processKey,
                }).print();
                break;
              }
            }
          }
        });
      }
    },
  },
};
